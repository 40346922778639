import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const FakeSearchInput = ({ value = '', onClick, onClear, placeholder = '' }) => (
  <div className={styles['fake-search-field-wrapper']}>
    <div
      tabIndex="0"
      className={`${styles['fake-search-field']} fake-search-field-testsuite`}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {value ? value : placeholder}
    </div>

    {value && (
      <div tabIndex="0" onClick={onClear} className={styles['clear-value-mobile']}>
        Clear
      </div>
    )}
  </div>
);

FakeSearchInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default FakeSearchInput;
