import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';

import styles from './index.module.scss';

const SearchInput = ({
  submitSearchValue,
  setValue = () => {},
  value = '',
  isMobile = false,
  onClose = () => {},
  inputPlaceholder = '',
  inputId = 'search-input',
}) => {
  const inputRef = useRef();

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitSearchValue(value);
      if (isMobile) {
        onClose();
      }
    }
    if (e.key === 'Esc' || e.key === 'Escape') {
      setValue('');
    }
  };

  const clearInput = (e) => {
    e.preventDefault();
    setValue('');
    inputRef.current.focus();
  };

  return (
    <div className={`${styles['search-input']} ${isMobile ? styles['search-mobile'] : ''}`}>
      <div className={styles['input-wrapper']}>
        <input
          id={inputId}
          placeholder={inputPlaceholder}
          value={value}
          name="text"
          ref={inputRef}
          onKeyDown={onInputKeyDown}
          onChange={(e) => setValue(e.target.value)}
          aria-label="query"
          title="Search input"
          aria-required="false"
          maxLength={200}
          data-component="search-input"
        />
        {value && (
          <div className={styles['clear-search-button']}>
            {!isMobile && (
              <a
                href="#"
                tabIndex="0"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    clearInput(e);
                  }
                }}
                onClick={clearInput}
                className={`${styles['clear-desktop']}`}
                title="Clear search"
                data-component="clear-search"
              >
                Clear
              </a>
            )}
            {isMobile && (
              <div
                tabIndex="0"
                onClick={clearInput}
                className={styles['clear-mobile']}
                title="Clear search"
              />
            )}
          </div>
        )}
      </div>

      {!isMobile && (
        <Button
          type="secondary"
          size="large"
          className={`${styles['search-submit-button']}`}
          onClick={() => submitSearchValue(value)}
          data-component="submit-search"
        >
          Search
        </Button>
      )}
      {isMobile && (
        <div className={`${styles['back-button']}`} title="Close search" onClick={onClose} />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  submitSearchValue: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  value: PropTypes.string,
  onClose: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  isMobile: PropTypes.bool,
  inputId: PropTypes.string,
};

export default SearchInput;
