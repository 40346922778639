import React from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../Link';
import styles from './index.module.scss';

const SuggestedSearches = ({ suggestedSearches, title = '', goToSuggestedSearch }) => (
  <div className={styles['suggested-searches']}>
    {title && <div className={styles['title']}>{title}</div>}

    <ul className={styles['items']}>
      {suggestedSearches.map((link) => (
        <li
          key={`list-link-${link.label}`}
          className={styles['item']}
          data-analytics="analytics-suggested-searches-header"
        >
          <Link {...link.nextLink}>
            <a>
              <span onClick={goToSuggestedSearch} onKeyPress={goToSuggestedSearch}>
                {link.label}
              </span>
            </a>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

SuggestedSearches.propTypes = {
  title: PropTypes.string,
  suggestedSearches: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
  ).isRequired,
  goToSuggestedSearch: PropTypes.func.isRequired,
};

export default SuggestedSearches;
