import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import DownArrow from '../../../../public/static/icons/navigation-chevron-down-desktop.svg';

import s from './index.module.scss';

const LoadMoreButton = ({ onClick, label = 'See more', className = '' }) => (
  <div className={`${s['load-more-button']} ${className}`}>
    <Button
      className={s['load-more-button__button']}
      type="secondary"
      tag="button"
      size="large"
      onClick={onClick}
      tabIndex="0"
    >
      {label}
      <DownArrow alt="" />
    </Button>
  </div>
);

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default LoadMoreButton;
