import React from 'react';
import PropTypes from 'prop-types';
import ClipboardIcon from '../../../../../public/static/icons/clipboard.svg';
import SearchBoldIcon from '../../../../../public/static/icons/search-bold.svg';
import FormattedText from '../../../FormattedText';
import Button from '../../../Button';

import s from './index.module.scss';

const NoResultsBlock = ({
  text = '',
  onButtonClick,
  analyticsClassName = '',
  buttonLabel = 'Clear and start again',
}) => (
  <div className={s['no-results-block']}>
    <div className={s['no-results-block__icon']}>
      <ClipboardIcon className={s['no-results-block__icon-clipboard']} />
      <SearchBoldIcon className={s['no-results-block__icon-search']} />
    </div>
    <FormattedText className={s['no-results-block__text']} text={text} />
    <Button
      type="secondary"
      size="large"
      tabIndex="0"
      onClick={onButtonClick}
      className={analyticsClassName}
      data-component="cancel-search-testsuite"
    >
      {buttonLabel}
    </Button>
  </div>
);

NoResultsBlock.propTypes = {
  buttonLabel: PropTypes.string,
  text: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  // Replace analyticsClassName variable with data-analytics parameter.
  analyticsClassName: PropTypes.string,
};

export default NoResultsBlock;
