export default (elementId) => {
  // IOS mobile devices open keyboard only when focus() happening during
  // onClick user action. So we add a fake input element and focus it during the action.
  // In the case keyboard will be displayed and we need just to switch focus
  // to the target input field.
  // See https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus.
  var tempElement = document.createElement('input');
  tempElement.style.position = 'absolute';
  // Avoid automatic scroll to the temp element
  tempElement.style.top = window.scrollY + 8 + 'px';
  tempElement.style.left = '0px';
  tempElement.style.height = 0;
  tempElement.style.opacity = 0;
  // Put this temp element as a child of the page <body> and focus on it
  document.body.appendChild(tempElement);
  tempElement.focus();

  // The keyboard is open. Now do a delayed focus on the target element.
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.focus();
    }
    // Remove the temp element.
    document.body.removeChild(tempElement);
  }, 100);
};
