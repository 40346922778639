const { BACKEND_URL, FRONTEND_CNET_URL } = require('./constants');

/**
 * Returns card icon url given resource type.
 */
const getResultCardIcon = (type, resourceType) => {
  const resourceTypes = {
    landing_page: '/static/icons/landing-page.svg',
    global_hunger_index: '/static/icons/global.svg',
    evaluation: '/static/icons/evaluation.svg',
    case_study: '/static/icons/case-study.svg',
    knowledge_matters_magazine: '/static/icons/case-study.svg',
    article: '/static/icons/case-study.svg',
    toolkit: '/static/icons/toolkit.svg',
    manual: '/static/icons/toolkit.svg',
    guideline: '/static/icons/toolkit.svg',
    technical_resource: '/static/icons/toolkit.svg',
    podcast: '/static/icons/podcast.svg',
    blog: '/static/icons/blog.svg',
    strategy: '/static/icons/strategy.svg',
    event: '/static/icons/event.svg',
    webinar: '/static/icons/webinar.svg',
  };

  let resourceTypeName = resourceType.toLowerCase().replace(' ', '_');
  if (type == 'publication_landing') {
    resourceTypeName = 'landing_page';
  }

  if (resourceTypeName in resourceTypes) {
    return resourceTypes[resourceTypeName];
  }

  return '/static/icons/publication.svg';
};

/**
 * Returns resource type given type and elastic search item.
 */
const getResourceType = (type, item) => {
  let resourceType = 'Publication';

  if (type) {
    if (type === 'publication_landing') {
      resourceType = 'Landing Page';
      if ('filter_programmes' in item) {
        resourceType = 'Flagship Programmes';
      } else if ('filter_approaches' in item) {
        resourceType = 'Approaches';
      } else if ('filter_themes_sectors' in item) {
        resourceType = 'Sectors';
      }
    } else {
      if ('filter_types' in item) {
        resourceType = item.filter_types[0];
      }
    }
  }
  return resourceType;
};

/**
 * Returns link to use for result card given type and item.
 */
const getResultCardLink = (type, item) => {
  let externalUrl = null;
  if (type === 'publication_external' && item.external_link) {
    externalUrl = item.external_link[0];
  }
  if (/^https?:/.test(item.url[0])) {
    externalUrl = item.url[0];
  }

  if (externalUrl) {
    const urlObj = new URL(externalUrl);
    const route = {
      as: null,
      href: externalUrl,
      isExternal: true,
      route: null,
      title: item.title[0],
      url: urlObj,
      target: '_blank',
      hostname: urlObj.hostname,
    };

    // Don't use `open in new window` icon in case when external url starts with FRONTEND_CNET_URL.
    const frontendUrlObj = new URL(FRONTEND_CNET_URL);
    if (urlObj.hostname === frontendUrlObj.hostname || urlObj.hostname === `www.${frontendUrlObj.hostname}`) {
      route.target = null;
      route.isExternal = false;
    }
    return route;
  }

  return {
    as: item.url[0],
    href: `/node/${type}`,
    isExternal: false,
    route: `/node/${type}`,
    title: item.title[0],
    url: item.url[0],
    target: '_self',
    hostname: '',
  };
};

/**
 * Returns image to use on result card given type and item.
 */
const getResultCardImage = (type, item) => {
  // Define hardcoded value for Drupal file system.
  const filesPrefix = '/sites/default/files';
  if (!item.image) {
    return {
      url: '',
      alt: '',
    };
  }
  // Get the second part of the original URL usually looking like
  // /media/folder/something.jpg.
  const publicFilePath = item.image[0].split(filesPrefix)[1];

  // Build the full URL of the image based on Drupal's image styles pattern.
  return {
    url: BACKEND_URL + `${filesPrefix}/styles/resources_list_item/public${publicFilePath}`,
    alt: '',
  };
};

// The export is done this way so that node.js can also
// require these functions.
module.exports = {
  getResultCardIcon,
  getResourceType,
  getResultCardLink,
  getResultCardImage,
};
