import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../../../../public/static/icons/knowledge-hub-search.svg';
import Link, { propTypes as linkPropTypes } from '../../../Link';

import s from './index.module.scss';

const NoResultsLinks = ({ title = '', links, analyticsClassName = '' }) => (
  <div className={s['no-results-links']}>
    <div className={s['no-results-links__explore-block']}>
      {title && <div className={s['no-results-links__title']}>{title}</div>}
      {links.map((link) => (
        <div className={s['no-results-links__link-item']} key={link.label}>
          <SearchIcon className={s['no-results-links__search-icon']} />
          <Link {...link.nextLink}>
            <a
              href={link.nextLink.url}
              className={`${s['no-results-links__link']} ${analyticsClassName}`}
              data-analytics={analyticsClassName || null}
            >
              {link.label}
            </a>
          </Link>
        </div>
      ))}
    </div>
  </div>
);

NoResultsLinks.propTypes = {
  title: PropTypes.string,
  analyticsClassName: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
  ).isRequired,
};

export default NoResultsLinks;
