import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../SearchInput';
import styles from './index.module.scss';
import SuggestedSearches from '../SuggestedSearhes';
import { propTypes as linkPropTypes } from '../../Link';

const SearchInputMobilePopup = ({
  submitSearchValue,
  setSearchValue = () => {},
  value = '',
  suggestedSearches = [],
  suggestedSearchesTitle = '',
  onClose = () => {},
  inputPlaceholder = '',
  inputId = 'search-input-mobile-popup',
  goToSuggestedSearch = () => {},
}) => (
  <div className={styles['search-input-mobile-popup']}>
    <SearchInput
      submitSearchValue={submitSearchValue}
      setValue={setSearchValue}
      value={value}
      isMobile
      onClose={onClose}
      inputPlaceholder={inputPlaceholder}
      inputId={inputId}
    />
    {suggestedSearches && !!suggestedSearches.length && (
      <SuggestedSearches
        title={suggestedSearchesTitle}
        suggestedSearches={suggestedSearches}
        goToSuggestedSearch={goToSuggestedSearch}
      />
    )}
  </div>
);

SearchInputMobilePopup.propTypes = {
  submitSearchValue: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func,
  value: PropTypes.string,
  suggestedSearches: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
  ),
  suggestedSearchesTitle: PropTypes.string,
  onClose: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  inputId: PropTypes.string,
  goToSuggestedSearch: PropTypes.func,
};

export default SearchInputMobilePopup;
